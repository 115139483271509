<!-- 菜单管理 -->
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="newCreate()">新建菜单</a-button>
  <a-table
    bordered
    rowKey="logicId"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    :loading="pagination.loading"
    @showSizeChange="pagination.onShowSizeChange"
  >
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal
    :title="modifyModal.title"
    v-model:visible="modifyModal.visible"
    :confirm-loading="modifyModal.loading"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <a-spin :spinning="modifyModal.status">
      <a-form
        ref="formRef"
        :model="modifyModal.data"
        :rules="modifyModal.rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-item label="菜单名称" name="name">
          <a-input v-model:value="modifyModal.data.name" />
        </a-form-item>
        <a-form-item label="排序参数" name="sort">
          <a-input-number
            v-model:value="modifyModal.data.sort"
            :min="1"
            :step="1"
            :precision="0"
            style="width: 100%"
          />
        </a-form-item>
        <!-- <a-form-item label="关联类型" name="joinType">
        <a-select
          v-model:value="modifyModal.data.joinType"
          placeholder="请选择"
        >
          <a-select-option value="1">话题</a-select-option>
          <a-select-option value="2">一级类目</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="关联名称" name="joinLogicId">
        <a-select
          v-model:value="modifyModal.data.joinLogicId"
          placeholder="请选择"
        >
          <a-select-option
            v-for="(item, i) in modifyModal.selects"
            :key="i"
            :value="item.logicId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        </a-form-item>-->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { ref, onMounted } from "vue";
import { saveOrUpdate, page, pageItem, detail } from "@/api/menu";
import { page as pageTopic } from "@/api/topic";
import { message } from "ant-design-vue";
export default {
  setup() {
    // 表格标题配置
    const columns = [
      { title: "ID", dataIndex: "logicId", width: "15%" },
      { title: "标题", dataIndex: "name", width: "65%" },
      {
        title: "操作",
        dataIndex: "operation",
        width: "20%",
        slots: { customRender: "operation" }
      }
    ];
    const rulesMod = {
      // 修改规则
      name: { required: true, message: "请输入菜单名称", trigger: "blur" },
      sort: {
        required: true,
        type: "number",
        message: "必填项",
        trigger: "blur"
      }
      // joinType: { required: true, message: "请选择关联类型", trigger: "blur" },
      // joinLogicId: { required: true, message: "请选择关联名称", trigger: "blur" }
    };
    const source = ref([]); // 显示的表格数据
    const formRef = ref();
    // ， ，
    /**
     * title: modal标题
     * visible: modal是否出现
     * loading：modal点击提交按钮是否加载
     * status：modal出现之后是否加载
     * form: 表单数据对象
     * data：网络请求的数据
     * rules: 表单提交轨辙
     * selects: 选择数据
     */
    const modifyModal = ref({
      title: "",
      visible: false,
      loading: false,
      status: false,
      data: {
        name: "",
        sort: 1
      },
      rules: rulesMod,
      selects: []
    });

    const pagination = ref({
      // 分页配置
      loading: true,
      position: "bottom",
      total: 0, // 条数
      defaultPageSize: 20, // 默认分页条数
      pageSizeOptions: ["5", "10", "15", "20", "30"],
      showSizeChanger: true, // 是否可以改变 pageSize
      showQuickJumper: true, // 是否可以快速跳转至某页
      showTotal: total => `共 ${total} 条`,
      hideOnSinglePage: false, // 只有一页时是否隐藏分页器
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows);
        pagination.value.loading = true;
        getList(selectedRowKeys, selectedRows);
      },
      onShowSizeChange: (current, size) => {
        pagination.value.loading = true;
        getList(1, size);
      }
    });
    // 加载修改页面
    const loadingMod = async logicId => {
      const { data, status } = await detail({ logicId });
      if (status == "200") {
        const { name, sort, logicId } = data;
        modifyModal.value.data = {
          name,
          sort,
          logicId
        }; // 将要修改的数据加载到modal
        return;
      }
      message.warning("系统繁忙");
    };
    // 触发修改页面
    const editCates = async row => {
      const { logicId } = row;
      // await loadingMod(logicId);
      const { data, status } = await detail({ logicId });
      if (status == "200") {
        const { name, sort, logicId } = data;
        modifyModal.value.data = {
          name,
          sort,
          logicId
        }; // 将要修改的数据加载到modal
      } else {
        message.warning("系统繁忙");
        return;
      }
      modifyModal.value.title = "修改菜单";
      modifyModal.value.visible = true; // 打开modal
      // modifyModal.value.status = true;
    };
    // 触发新建页面
    const newCreate = () => {
      modifyModal.value.data = { name: "", sort: 1 }; // 初始化modal重的表格数据
      modifyModal.value.status = false; // 停止modal加载状态
      modifyModal.value.loading = false; // 提交按钮加载状态
      modifyModal.value.title = "新建菜单";
      modifyModal.value.visible = true; // 打开modal
    };

    const handlerCancel = async () => {
      modifyModal.value.status = false;
      modifyModal.value.loading = false; // 提交按钮加载状态
    };
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          const exce = async () => {
            modifyModal.value.loading = true;
            const { name, sort, logicId } = modifyModal.value.data;
            const { status, msg } = await saveOrUpdate({
              name,
              sort,
              logicId
            });
            if (status == "200") {
              message.success(msg);
              modifyModal.value.loading = false;
              modifyModal.value.visible = false;
              getList();
              return;
            }
            modifyModal.value.loading = false;
            message.error(msg);
          };
          exce();
        })
        .catch(error => {
          console.log("error", error);
        });
    };
    // 获取分页数据
    async function getList(pageNo = "1", pageSize = "20") {
      const { data, status } = await page({ pageNo, pageSize });
      if (status == "200") {
        const { total, dataOnThisPage } = data;
        pagination.value.total = total || 0;
        source.value = dataOnThisPage || [];
      }
      pagination.value.loading = false;
    }
    onMounted(getList);
    onMounted(async () => {
      const { data: dataTopic } = await pageTopic({
        pageNo: "1",
        pageSize: "1000"
      });
      const { data: dataItem } = await pageItem({
        pageNo: "1",
        pageSize: "1000",
        level: "1"
      });
      let topic = dataTopic.dataOnThisPage;
      let item = dataItem.dataOnThisPage;
      topic =
        topic == undefined
          ? []
          : topic.map(item => {
            return { logicId: item.logicId, name: item.name };
          });
      item =
        item == undefined
          ? []
          : item.map(item => {
            return { logicId: item.logicId, name: item.name };
          });
      modifyModal.value.selects = [...topic, ...item];
    });
    return {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate
    };
  }
};
</script>

<style>
</style>
